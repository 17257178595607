import { createContext, useContext, useState } from "react"
import { extractId } from "../utils/transformers.js";

export const EndUsersContext = createContext(null)


export function EndUsersProvider({ children }) {

    const [endUsersMap, setEndUsersMap] = useState(null)
    const [segmentsMap, setSegmentsMap] = useState(null)
    const [paginationData, setPaginationData] = useState({})
    const [eventsData, setEventsData] = useState(null)
    const [seasonTicketsData, setSeasonTicketsData] = useState(null)
    const [activityData, setActivityData] = useState(null)
    const [endUsersFullDataMap, setEndUsersFullDataMap] = useState({})
    const [allAccountsData, setAllAccountsData] = useState(null)

    /* Map relations, an object which maps higher entity to it relations - { idOfTheEntity: relatedEntitiesId[] }  */
    const [sellerAccountToEndUsersMap, setSellerAccountToEndUsersMap] = useState({})
    const [sellerAccountToSegmentMap, setSellerAccountToSegmentMap] = useState({})
    const [segmentToEndUsersMap, setSegmentToEndUsersMap] = useState({})
    const [reload, setReload] = useState(0)

    /* End Users */
    const updateManyEndUsers = (endUsers) => {
        const newEndUsersMap = JSON.parse(JSON.stringify(endUsersMap ? endUsersMap : {}))
        endUsers.forEach(endUser => { newEndUsersMap[endUser._id] = endUser })
        setEndUsersMap(newEndUsersMap)
    }
    const updateSellerAccountToEndUsersRelation = (sellerAccountId, endUsers) => {
        const ids = endUsers.map(extractId)
        const newSellerAccountToEndUsersMap = JSON.parse(JSON.stringify(sellerAccountToEndUsersMap))
        const newArray = newSellerAccountToEndUsersMap[sellerAccountId]?.length ? [...newSellerAccountToEndUsersMap[sellerAccountId], ...ids] : ids
        newSellerAccountToEndUsersMap[sellerAccountId] = [...new Set(newArray)] // Force to remove duplications
        setSellerAccountToEndUsersMap(newSellerAccountToEndUsersMap)
    }
    const getEndUser = (id) => endUsersMap[id]
    const getEndUsersOfSellerAccount = (sellerAccountId) => sellerAccountToEndUsersMap[sellerAccountId]

    const updateEndUsersFullData = (endUserId, accounts, events, userEvents, endUser, eventOrders, endUserSeasonTickets, crmEvents) => {
        const newMapData = JSON.parse(JSON.stringify(endUsersFullDataMap))
        newMapData[endUserId] = { ...newMapData[endUserId] }

        if (accounts) {
            newMapData[endUserId].accounts = accounts
        }

        if (events) {
            newMapData[endUserId].events = events
        }

        if (userEvents) {
            newMapData[endUserId].userEvents = userEvents
        }

        if (endUser) {
            newMapData[endUserId].endUser = endUser
        }

        if (eventOrders) {
            newMapData[endUserId].eventOrders = eventOrders
        }

        if (endUserSeasonTickets) {
            newMapData[endUserId].endUserSeasonTickets = endUserSeasonTickets
        }

        if (crmEvents) {
            newMapData[endUserId].crmEvents = crmEvents
        }

        // newMapData[endUserId] = { accounts, events, userEvents, endUser, eventOrders, endUserSeasonTickets, crmEvents }
        setEndUsersFullDataMap(newMapData)
    }
    const updateEndUserData = (endUserId, endUserData) => {
        const newMapData = JSON.parse(JSON.stringify(endUsersFullDataMap))
        newMapData[endUserId].endUser = endUserData
        setEndUsersFullDataMap(newMapData)
    }
    const getEndUserFullData = (endUserId) => endUsersFullDataMap[endUserId]

    /* Segments */
    const updateManySegments = (segments) => {
        const newSegmentsMap = JSON.parse(JSON.stringify(segmentsMap ? segmentsMap : {}))
        segments.forEach(segment => { newSegmentsMap[segment._id] = segment })
        setSegmentsMap(newSegmentsMap)
    }
    const updateSellerAccountToSegmentRelation = (sellerAccountId, segments) => {
        const ids = segments.length ? segments.map(extractId) : []
        const newSellerAccountToSegmentsMap = JSON.parse(JSON.stringify(sellerAccountToSegmentMap))
        const newArray = newSellerAccountToSegmentsMap[sellerAccountId]?.length ? [...newSellerAccountToSegmentsMap[sellerAccountId], ...ids] : ids
        newSellerAccountToSegmentsMap[sellerAccountId] = newArray // [ ...new Set(newArray)] // Force to remove duplications
        setSellerAccountToSegmentMap(newSellerAccountToSegmentsMap)
    }
    const getSegment = (id) => segmentsMap[id]
    const getSegmentsOfSellerAccount = (sellerAccountId) => sellerAccountToSegmentMap[sellerAccountId]
    // segment - should be array of objects like this { segmentId: string, endUsers: array of endUsers }
    const updateSegmentToEndUsersRelation = (segments, shouldRemove) => {
        const newSegmentToEndUsersMap = JSON.parse(JSON.stringify(segmentToEndUsersMap))
        for (const { segmentId, endUsers } of segments) {
            newSegmentToEndUsersMap[segmentId] = newSegmentToEndUsersMap[segmentId] ? newSegmentToEndUsersMap[segmentId] : {}
            for (const endUserRelation of endUsers) {
                newSegmentToEndUsersMap[segmentId][endUserRelation.endUser] = shouldRemove ? false : true
            }
        }
        setSegmentToEndUsersMap(newSegmentToEndUsersMap)
    }

    return (
        <EndUsersContext.Provider
            value={{
                updateManyEndUsers,
                getEndUser,
                updateSellerAccountToEndUsersRelation,
                getEndUsersOfSellerAccount,
                updateManySegments,
                updateSellerAccountToSegmentRelation,
                getSegment,
                getSegmentsOfSellerAccount,
                updateSegmentToEndUsersRelation,
                updateEndUsersFullData,
                getEndUserFullData,
                updateEndUserData,
                segmentsMap,
                allAccountsData,
                setAllAccountsData,
                segmentToEndUsersMap,
                paginationData,
                setPaginationData,
                eventsData,
                setEventsData,
                seasonTicketsData,
                setSeasonTicketsData,
                activityData,
                setActivityData,
                reload,
                setReload,
            }}>
            {children}
        </EndUsersContext.Provider>
    )
}

export const useEndUsersContext = () => {
    const context = useContext(EndUsersContext)
    if (!context) {
        throw new Error('useEndUsersContext context must be used inside EndUsersProvider')
    }
    return context
}
