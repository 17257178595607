import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/minimal/useResponsive';
// config
import { NAV } from '../../../config-global';
// components
import Logo from '../../../components/minimal/logo';
import Scrollbar from '../../../components/minimal/scrollbar';
import { NavSectionVertical } from '../../../components/minimal/nav-section';
//
import navConfig from './config-navigation';
import NavDocs from './NavDocs';
import NavAccount from './NavAccount';
import NavToggleButton from './NavToggleButton';
import { ROLES_LABELS, useRole } from "../../../pages/common/guards/RoleGuard.jsx";
import { useMarketplaceContext } from "../../../context-state/MarketplaceContext.jsx";
import { useAuthContext } from "../../../context-state/AuthContext.jsx";

// ----------------------------------------------------------------------

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function NavVertical({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const paramsObject = useParams()
  const isDesktop = useResponsive('up', 'lg');
  const { marketplace } =  useMarketplaceContext()
  const { user } = useAuthContext()

  // Filter the routes by the dependOn and isCrmRoute,
  const navConfigToPresent = navConfig.filter(navItem => {
      if (navItem.items?.length) {
          navItem.items = navItem.items
              .filter(item => {
                  if (item.isSellerRoute) {
                      return user.isSeller
                  }
                  if (item.isSellersManagerRoute) {
                      return marketplace.isSellersManager
                  }
                  if (item.isCrmRoute) {
                      return marketplace.hasCRM
                  }
                  if (item.isMarketPlaceOwnerRoute) {
                      return user.isMarketPlaceOwner
                  }
                  if (item.isAllowSeasonTicketRoute) {
                      return marketplace.isAllowSeasonTicket
                  }
                  return true
              })
              .map(item => {
                  if (item.children?.length) {
                      item.children = item.children.filter(childRoute => {
                          let shouldRender = true

                          if (childRoute.isAllowSeasonTicketRoute) {
                              shouldRender = marketplace.isAllowSeasonTicket
                          }
                          if (!shouldRender) {
                              return false
                          }
                          if (childRoute.isCrmRoute) {
                              shouldRender = marketplace.hasCRM
                          }
                          if (!shouldRender) {
                              return false
                          }
                          if (childRoute.isSellerRoute) {
                              shouldRender = user.isSeller
                          }
                          if (!shouldRender) {
                              return false
                          }
                          if (childRoute.isSellersManagerRoute) {
                              shouldRender = marketplace.isSellersManager
                          }
                          if (!shouldRender) {
                              return false
                          }
                          if (childRoute.isMarketPlaceOwnerRoute) {
                              shouldRender = user.isMarketPlaceOwner
                          }
                          return shouldRender
                      })
                  }
                  return item
          })
      }

      if (!navItem.dependOn) {
          return true
      }

      return Object.keys(paramsObject).includes(navItem.dependOn);
  })
    // console.log(navConfigToPresent);


  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Logo />

        <NavAccount />
      </Stack>

      <NavSectionVertical data={navConfigToPresent} />

      <Box sx={{ flexGrow: 1 }} />

      <NavDocs />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {/*<NavToggleButton />*/}

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
